<template>
  <b-card class="shop-agents">
    <div class="row mb-1">
      <div v-for="(headerLabel, hk) in headerLabels" class="col-12 col-md-3" :key="`hk_${hk}`">
        <HeaderCard 
          :counter="headerLabel.count"
          :img="headerLabel.img"
          :label="headerLabel.label"
        />
      </div>
    </div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('From Date')">
          <b-form-datepicker
            v-model="dataInfo.fromDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('To Date')">
          <b-form-datepicker
            v-model="dataInfo.toDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mt-auto mb-1"
      >
        <b-button
          variant="primary"
          @click="loadAgentDashboard"
        >
          {{ $t('Search') }}
        </b-button>
      </b-col>
      <b-col cols="12">
        <good-data-table
          ref="agentsGrid"
          :columns="agentsColumns"
          :total-column="'response.responseData.total'"
          :data-column="`Object.values(response.responseData.data).filter(x => typeof x === 'object')`"
          :api-endpoint="'/shop/agents/data/statistics/earning'"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showAgentSelectionModal"
      :no-close-on-backdrop="true"
      :title="$t('Choose Orders')"
      size="xl"
      hide-footer
      @hidden="showAgentSelectionModal = false"
    >
      <div class="row">
        <div class="col-12">
          <good-data-table
            ref="orderEarningsTable"
            :mode="'local'"
            :no-serial="true"
            :columns="orderEarningColumns"
            :load-local-data="true"
            :chBoxenabled="false"
          />
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import moment from 'moment'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'
import { isEmpty } from '@/utilities'
import { mapActions } from 'vuex'
import HeaderCard from '@/components/common/HeaderCard.vue';

export default {
  components: {
    GoodDataTable, HeaderCard
  },
  data() {
    return {
      orderEarningData: [],
      orderEarningAmount: 0,
      dataInfo: {
        fromDate: '',
        toDate: '',
      },
      showAgentSelectionModal: false,
      agentOrdersInfo: [],
      agentInfo: null,
      headerLabels: [],
      agentsColumns: [
        {
          label: 'Agent',
          field: 'agent',
          useHtml: true,
          excelHtml: props => `${props.agent}`,
          renderer: props => `<div class="d-flex align-items-center">
                                <img class="agent-image-info" src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />
                                <span>${props.agent}</span>
                              </div>`,
        },
        {
          label: "Delivered",
          field: "delivered",
        },
        {
          label: "Cancelled",
          field: "cancelled",
        },
        {
          label: "Fixed Pay",
          field: "basic_salary",
        },
        {
          label: "Variable Pay",
          field: "variable_pay",
        },
        {
          label: "Distance Pay",
          field: "distance_pay",
        },
        {
          label: "Total Pay",
          field: "total_pay",
        },
        {
          label: "Total Distance",
          field: "total_distance",
        },
        {
          label: "Total Hours",
          field: "total_hours",
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('info'),
              text: 'Collect COD',
              action: props => {
                this.orderEarning(props)
              },
            },
          ],
        },
      ],
      orderEarningColumns: [
        {
          label: 'Created Date',
          field: 'created_at',
        },
        {
          label: 'Order No',
          field: 'store_order_invoice_id',
        },
        {
          label: "Variable Pay",
          field: "variable_pay",
        },
        {
          label: "Distance Pay",
          field: "distance_pay",
        },
        {
          label: "Order Pay",
          field: "total_pay",
        },
        {
          label: "Total Distance",
          field: "total_distance",
        },
      ]
    }
  },
  methods: {
    ...mapActions('store', ['getAgentEarningStatatics']),
    loadAgentDashboard() {
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      this.$refs.agentsGrid.loadItems(dataPayload.join('&'))
      this.getStatitics()
    },
    orderEarning(payload) {
      this.agentInfo = payload
      const { agentsEarningOrders } = useStoreUi()
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      let dataPayloadStr = dataPayload.join('&')
      if(!isEmpty(dataPayload)){
        dataPayloadStr = `&${dataPayloadStr}`
      }
      showLoader()
      agentsEarningOrders(`?agent_id=${payload.id}${dataPayloadStr}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.agentOrdersInfo = data.responseData
            this.showAgentSelectionModal = true
            setTimeout(() => {
              this.$refs.orderEarningsTable.setLocalData(this.agentOrdersInfo)
            }, 100);
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStatitics(){
      this.headerLabels = []
      const params = {}
      if(!isEmpty(this.dataInfo.fromDate)){
        params.from = moment(this.dataInfo.fromDate).format('YYYY-MM-DD')
      }
      if(!isEmpty(this.dataInfo.toDate)){
        params.to = moment(this.dataInfo.toDate).format('YYYY-MM-DD')
      }
      this.getAgentEarningStatatics(params).then(r => {
        this.headerLabels = r
      })
    }
  },
  mounted(){
    this.getStatitics()
  }
}
</script>

<style>
.agent-image-info {
  width: 40px;
  height: 40px;
  border-radius: 10%;
  margin-right: 10px;
}
</style>
